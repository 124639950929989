<template>
  <NewContainerCard :locationMsg="locationMsg" :width="85">
    <!-- <Location :locationMsg="locationMsg"></Location>
      <div class="line"></div> -->
    <div class="imageTextContent">
      <div class="imageTextLeft">
        <NewRank
          :defaultValue="this.params.keyword"
          @orderType="orderTypeHandle"
          @searchHandle="searchHandle"
        ></NewRank>
        <div
          v-if="List.length > 0"
          class="listContainer"
          v-empty="List"
          v-loading="fullscreenLoading"
        >
          <div
            class="artContent"
            v-for="(item, index) in List"
            :key="item.title + index"
            @click="toInfoDetails(item.billid)"
            @mouseenter="handleMouseenter(index)"
            @mouseleave="() => isActive = -1"
          >
            <div
              class="art"
              :class="[index + 1 === List.length ? 'borderNone' : '']"
            >
              <div class="arcticleTttle">
                <!-- <div class="dot"></div> -->
                <div class="tittle" :style="{color: isActive == index? '#2885FF' : '#101010' }">
                  {{ item.title }}
                </div>
                <!-- <div class="articleTime">
                  {{ item.make_time }}
                </div> -->
              </div>


              <div class="records">
                  <div class="img-box" v-if="item.thumb">
                    <img :src="httpConfig.baseURL+item.thumb" alt="">
                  </div>
        
                <div class="describe">
                  <div class="preface">
                    {{ item.content }}
                  </div>
                  <div class="arcticleTime">
                    <span>{{ item.send_data }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div v-else class="listEmpty">
          <img src="../../assets/images/空白.png" alt="" />
          <p class="empty-text">暂无内容</p>
        </div>
        <div class="paginationContainer" :style="styleObj">
          <Pagination
            @changeIndex="setCurrentPage"
            :total="total"
            :totalPage="totalPage"
            :pageSize="params.pageSize"
          >
          </Pagination>
        </div>
      </div>
      <div class="imageTextRight">
        <keep-alive>
          <Classify @selectTag="selectTagHandle"></Classify>
        </keep-alive>
        <keep-alive>
          <Hot @jumpDetails="jumpDetails"></Hot>
        </keep-alive>
      </div>
    </div>
  </NewContainerCard>
</template>
<script>
import Location from "../../components/Location.vue";
import Technology from "../../components/Info/Technology.vue";
import NewRank from "../../components/Info/newRank.vue";
import Classify from "../../components/Info/Classify.vue";
import Hot from "../../components/Info/Hot.vue";
import NewContainerCard from "../../components/newContainerCard.vue";
import Pagination from "../../components/Pagination.vue";
import { getBizinfoById, getBizinfos } from "../../api/info/info.js";
import { mapGetters } from "vuex";
import { getPlainText } from "../../common/utils/tool.js";
import httpConfig from "../../common/utils/config";
export default {
  computed: {
    ...mapGetters(["bizColumnsList"]),
    ...mapGetters(["topHotInfo"]),
  },
  data() {
    return {
      List: [],
      httpConfig,
      total: 0,
      isActive: -1,
      totalPage: 0,
      fullscreenLoading: false,
      // locationMsg: [{ name: "新闻动态", router: "imageText" }],
      locationMsg: [],
      styleObj:{
        display:'',
      },
      params: {
        type: "",
        keyword: "",
        orderstr: "if_top", //click_num
        pageNo: 1,
        pageSize: 5,
      },
    };
  },
  created() { 
    let type = this.$route.query.type;
    this.params.type = type;
    this.params.keyword = this.$route.query.keyword;
    this.getData();
  },
  methods: {
    jumpDetails(id) {
      this.$router.replace({
        path: "/imageText/infoDetails",
        query: {
          id: id,
        },
      });
    },
    setCurrentPage(val) {
      this.params.pageNo = val;
      this.getData();
    },
    selectTagHandle(type) {
      this.params.type = type;
      this.getData();
    },
    handleMouseenter(index) {
      console.log('鼠标进入', index)
      this.isActive = index
    },
    searchHandle(val) {
      // console.log("输入的值为:",val)
      this.params.keyword = val;
      if (!val) {
        this.params.type = "";
      }
      this.getData();
    },
    orderTypeHandle(orderType) {
      console.log(orderType);
      this.params.orderstr = orderType;
      this.getData();
    },
    async getData() {
      this.fullscreenLoading = true;
      let res = await getBizinfos(this.params);
      this.List = res.data.items.map((item) => {
        let tmp = item;
        tmp.content = getPlainText(getPlainText(item.content));
        return tmp;
      });
      console.log("数据是:",this.List)
      this.total = res.data.totalCount;
      // this.totalPage = res.data.totalPage
      this.totalPage = Math.ceil(res.data.totalCount / this.params.pageSize);

      this.fullscreenLoading = false;
      console.log("政策公共数据:",this.List)
      // 搜索没数据时
      // console.log(this.List.length)
      if(this.List.length == 0){
        this.styleObj.display = 'none' 
      }
      else{
        // 搜索没有输入，就原页面
        this.styleObj.display = ''
      }

    },
    getImages() {},
    toInfoDetails(id) {
      this.$router.push({
        path: "/imageText/infoDetails",
        query: {
          id: id,
        },
      });
    },
  },
  components: {
    Pagination,
    Location,
    Technology,
    // Rank,
    NewRank,
    Classify,
    Hot,
    NewContainerCard,
  },
};
</script>
<style scoped lang="scss">
.content {
  height: 990px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #ffffff;
  // padding: 0px 0 10px 0px;
}
.line {
  width: 100%;
  height: 1px;
  background: #d8d8d8;
  margin-top: 20px;
  margin-bottom: 20px;
}
.ImageText {
  width: 68.7%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 9px 3px rgba(204, 221, 244, 0.5);
  border-radius: 20px;
  filter: blur(0px);
}

.listContainer {
  height: 800px;
  padding-left: 30px;
  padding-right: 30px;
}
.imageTextContent {
  display: flex;
  width: 100%;
  // padding: 20px 0;
}

.listEmpty {
  height: 220px;
  width: 756px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .empty-text {
    margin-top: 16px;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #8896A7;
  }
}
.imageTextLeft {
  width: 756px;

  .artContent {
    display: flex;
    // width: 100%;
    box-sizing: border-box;
    width: 696px;
    // height: 120px;
    // justify-content: space-between;
    flex-direction: column;
    align-items: center;
    // margin-bottom: 20px;
    padding: 24px 0px 24px 0px;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
    .records {
      display: flex;
      flex-direction: row;
      .img-box {
        margin-right: 12px;
        img {
          width: 136px;
          height: 80px;
          border-radius: 6px;
        }
      }

      .describe {
        height: 40px;
        width: 756px;
        // padding-left: 12px;
        box-sizing: border-box;
        .preface {
          // width: 100%;
          width: 548px;
          height: 24px;
          line-height: 28px;
          color: #999999;
          font-family: 思源黑体 CN Regular;
          font-size: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          margin-bottom: 12px;
        }
      }
    }
    .art {
      height: 100%;
      width: 100%;
      // border-bottom: #eaeaea solid 1px;
      .arcticleTttle {
        // display: flex;
        // align-items: center;
        // justify-content: space-around;
        // justify-content: flex-start;
        width: 696px;
        height: 18px;
        margin-bottom: 14px;
        .dot {
          width: 8px;
          height: 8px;
          background: #14569f;
          margin-right: 12px;
        }
        .tittle {
          // flex: 1;
          height: 20px;
          max-width: 750px;
          line-height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 18px;
          // font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-family: 思源黑体 CN Regular;
          font-weight: 500;
          color: #101010;
        }

        .articleTime {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-left: 20px;
          font-size: 20px;
          // font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-family: 思源黑体 CN Regular;
          font-weight: 500;
          color: #999999;
        }
      }
      .bottom {
        width: 696px;
        margin-bottom: 14px;
        // display: flex;
        .articleContent {
          // margin: 16px 75px 30px 20px;
          font-size: 14px;
          max-width: 750px;
          // font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-family: 思源黑体 CN Regular;
          font-weight: 400;
          color: #888888;
          // line-height: 24px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .arcticleTime {
        width: 696px;
        span {
          font-size: 12px;
          font-family: 思源黑体 CN Regular;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
  // 分页器
  .paginationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: 38px;
    height: 78px;
    width: 756px;
  }
}

.imageTextRight {
  width: 300px;
  box-sizing: border-box;
}
.borderNone {
  border: none !important;
}
.Hot {
  margin-top: 20px;
}
@media screen and (max-width: 992px) {
  .imageTextLeft {
    width: 100%;
  }
  .imageTextRight {
    /* display: flex;
    justify-content: space-between; */
    // width: 100%;
  }
}
</style>